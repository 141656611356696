<template>
  <div class="enroll-new content">
    <b-form @submit.prevent="submitFormStep1">
      <!-- ERRORS -->
      <b-alert
        v-if="formErrors.non_field_errors !== undefined"
        variant="danger"
        show
      >
        {{ formErrors.non_field_errors[0] }}
      </b-alert>

      <!-- PROGRAMS -->
      <h1>
        Select a Program
      </h1>
      <div class="py-3" />
      <transition-slide mode="out-in">
        <p v-if="programs.status == 'loading'">
          Loading programs
          <spinner color="var(--primary)" />
        </p>
        <div v-else>
          <template v-if="programs.status == 'loaded'">
            <b-form-group>
              <b-form-radio-group
                v-model="formFields.program"
                name="program"
                :state="formErrors.program === undefined ? null : false"
                required
              >
                <program-radio-card
                  v-for="program of programs.results"
                  :key="`program${program.id}`"
                  :program="program"
                  :active="formFields.program[0] === String(program.id)"
                />
              </b-form-radio-group>
            </b-form-group>
          </template>
        </div>
      </transition-slide>

      <!-- DISCOUNT -->
      <transition-slide mode="out-in">
        <div v-show="formFields.program">
          <transition-slide mode="out-in">
            <b-form-group
              v-if="formFields.program && priceData[formFields.program].discounted.amount === null"
              label="Discount code"
              label-for="discount_code"
              class="discount_code-form-group"
            >
              <div>
                <b-form-input
                  id="discount_code"
                  v-model="formFields.discount_code"
                  :state="formErrors.discount_code === undefined ? null : false"
                  :aria-describedby="formErrors.discount_code === undefined ? false : 'discount_code-feedback'"
                  @keydown.prevent.enter="applyDiscount"
                />
                <b-form-invalid-feedback
                  v-if="formErrors.discount_code !== undefined"
                  id="discount_code-feedback"
                >
                  {{ formErrors.discount_code[0] }}
                </b-form-invalid-feedback>
              </div>
              <b-button
                v-if="formFields.program"
                variant="primary"
                size="sm"
                :disabled="priceData[formFields.program].discounted.status == 'loading'"
                @click="applyDiscount"
              >
                <template v-if="priceData[formFields.program].discounted.status == 'loading'">
                  Applying
                  <spinner />
                </template>
                <template v-else>
                  Apply
                </template>
              </b-button>
            </b-form-group>
          </transition-slide>

          <!-- DUE -->
          <p v-if="stripeScriptStatus == 'loading'">
            Loading payment module
            <spinner color="var(--primary)" />
          </p>
          <div v-else-if="stripeScriptStatus == 'loaded'">
            <p
              v-if="formFields.program"
              class="total-cost mb-2"
            >
              Due:

              <template v-if="priceData[formFields.program].discounted.amount === null">
                {{ formatPrice(priceData[formFields.program].regular) }}
              </template>
              <template v-else>
                <s>{{ formatPrice(priceData[formFields.program].regular) }}</s>
                <span>{{ formatPrice(priceData[formFields.program].discounted.amount) }}</span>

                <b-button
                  type="submit"
                  variant="link"
                  size="sm"
                  :disabled="formSubmitting"
                  @click="removeDiscount"
                >
                  Remove discount
                </b-button>
              </template>

              <br>

              <small v-if="twoInstallmentsSelected">
                You will be billed the first installment today, and the second
                installment one month from now.
              </small>
              <small v-else-if="subscriptionSelected">
                You will be billed monthly on this same day.
              </small>
            </p>

            <!-- CARD -->
            <div v-show="formFields.program && (priceData[formFields.program].discounted.status != 'loaded' || priceData[formFields.program].discounted.amount > 0)">
              <div id="card-element" />
              <div
                id="card-errors"
                class="alert-danger"
                role="alert"
              />
            </div>
          </div>

          <!-- AGREE -->
          <b-form-group>
            <b-form-checkbox
              v-model="formFields.agree"
              name="agree"
              class="mt-3"
              required
            >
              I agree to abide by the terms of use
            </b-form-checkbox>
          </b-form-group>
        </div>
      </transition-slide>

      <!-- ENROLL -->
      <b-button
        type="submit"
        variant="primary"
        :disabled="stripeScriptStatus != 'loaded' || formFields.program == '' || formSubmitting"
      >
        <template v-if="formSubmitting">
          Enrolling
          <spinner />
        </template>
        <template v-else>
          Enroll
        </template>
      </b-button>
    </b-form>
  </div>
</template>

<script>
import Spinner from '@/components/Spinner.vue';
import TransitionSlide from '@/components/TransitionSlide.vue';
import ProgramRadioCard from '@/components/programs/ProgramRadioCard.vue';
import Stripe from '@/mixins/Stripe.vue';


export default {
  name: 'EnrollNew',
  components: {
    Spinner,
    TransitionSlide,
    ProgramRadioCard,
  },
  mixins: [Stripe],
  data() {
    return {
      formSubmitting: false,
      formErrors: {},
      formSuccess: false,
      formFields: {
        program: '',
        update_payment_method: false,
      },
      priceData: {},
      programs: { status: 'loading' },
      payment_method: null,
    };
  },
  computed: {
    userData() {
      return this.$store.state.userData;
    },
    subscriptionSelected() {
      try {
        return this.priceData[this.formFields.program].type === 'monthly_subscription';
      } catch {
        return false;
      }
    },
    twoInstallmentsSelected() {
      try {
        return this.priceData[this.formFields.program].type === 'two_installments';
      } catch {
        return false;
      }
    },
  },
  created() {
    // ADD ONCE MULTIPLE PM
    // this.fetchPaymentMethod();
    this.loadPrograms();
  },
  methods: {
    async loadPrograms() {
      this.programs = { status: 'loading' };

      let response;

      try {
        response = await fetch(
          `${process.env.VUE_APP_API_URL}users/enrollment_prices/?user=${this.userData.id}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          },
        );
      } catch (e) {} // eslint-disable-line no-empty

      if (response && response.status === 200) {
        const responseJson = await response.json();

        responseJson.forEach((program) => {
          program.payment_methods.forEach((pm) => {
            this.$set(
              this.priceData,
              `${program.id}-${pm.id}`,
              {
                total: pm.total,
                regular: pm.price,
                type: pm.type,
                discounted: {
                  status: 'idle',
                  amount: null,
                },
              },
            );
          });
        });

        this.programs = {
          status: 'loaded',
          results: responseJson,
        };
      } else {
        this.programs = { status: 'error' };
      }
    },
    // NOT USED
    async fetchPaymentMethod() {
      let response;

      try {
        response = await fetch(
          `${process.env.VUE_APP_API_URL}users/${this.userData.id}/default_payment_method/`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          },
        );
      } catch (e) {} // eslint-disable-line no-empty

      if (response && response.status === 200) {
        const responseJson = await response.json();
        this.payment_method = responseJson.data;
      } else if (response && response.status === 404) {
        this.payment_method = false;
      } else {
        this.formErrors = {
          non_field_errors: [
            'Unable to communicate with the server. Please check your '
            + 'connection and try again.',
          ],
        };
      }
    },
    // NOT USED
    async applyDiscount() {
      if (this.formFields.discount_code.trim() !== '') {
        delete this.formErrors.discount_code;

        this.priceData[this.formFields.program].discounted.status = 'loading';

        const postData = {
          program: this.formFields.program,
          discount_code: this.formFields.discount_code.trim(),
        };

        let response;

        try {
          response = await fetch(
            `${process.env.VUE_APP_API_URL}users/enrollment_prices/`,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(postData),
            },
          );
        } catch (e) {} // eslint-disable-line no-empty

        if (response && [200, 400].includes(response.status)) {
          const responseJson = await response.json();

          if (response.status === 200) {
            this.priceData[this.formFields.program] = {
              regular: responseJson.regular,
              discounted: {
                status: 'loaded',
                amount: responseJson.discounted,
                code_used: postData.discount_code,
              },
              type: this.priceData[this.formFields.program].type,
            };

            this.formFields.discount_code = '';
          } else {
            this.$set(this.formErrors, 'discount_code', [responseJson]);
            this.priceData[postData.program].discounted.status = 'error';
          }
        } else {
          this.$set(this.formErrors, 'discount_code', [
            'Unable to communicate with the server. Please check your '
            + 'connection and try again.',
          ]);
          this.priceData[postData.program].discounted.status = 'error';
        }
      } else {
        this.$set(this.formErrors, 'discount_code', ['This field may not be blank.']);
      }
    },
    removeDiscount() {
      this.priceData[this.formFields.program].discounted = {
        status: 'idle',
        amount: null,
      };
    },
    formatPrice(price) {
      return `$${price.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',').replace('.00', '')}`;
    },
    async submitFormStep1() {
      this.formErrors = {};
      this.formSubmitting = true;

      if (
        this.priceData[this.formFields.program].discounted.status !== 'loaded'
        || this.priceData[this.formFields.program].discounted.amount > 0
      ) {
        // SUBSCRIPTION ONBOARDING
        if (this.subscriptionSelected || this.twoInstallmentsSelected) {
          let customerStripeId;

          if (!this.userData.stripe_customer_id) {
            const billingEmail = this.userData.email;

            let customerResponse;

            try {
              // CREATE CUSTOMER
              customerResponse = await fetch(
                `${process.env.VUE_APP_API_URL}users/create_customer/`,
                {
                  method: 'post',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({
                    email: billingEmail,
                  }),
                },
              );
            } catch (e) {} // eslint-disable-line no-empty

            if (customerResponse && [201, 403].includes(customerResponse.status)) {
              const responseJson = await customerResponse.json();

              if (customerResponse.status === 201) {
                customerStripeId = responseJson.customer.id;
              } else {
                this.formErrors = responseJson;
                this.formSubmitting = false;
                return;
              }
            } else {
              this.formErrors = {
                non_field_errors: [
                  'Unable to communicate with the server. Please check your '
                  + 'connection and try again.',
                ],
              };
              this.formSubmitting = false;
            }
          } else {
            customerStripeId = this.userData.stripe_customer_id;
          }

          if (customerStripeId) {
            this.stripe.createPaymentMethod({
              type: 'card',
              card: this.card,
            }).then((result) => {
              if (result.error) {
                const cardErrors = document.getElementById('card-errors');
                cardErrors.classList.add('alert');
                cardErrors.textContent = result.error.message;
                this.formSubmitting = false;
              } else if (typeof customerStripeId === 'boolean') {
                this.submitFormStep2('', result.paymentMethod.id);
              } else {
                this.submitFormStep2(customerStripeId, result.paymentMethod.id);
              }
            });
          } else {
            this.formErrors = {
              non_field_errors: [
                'Unable to communicate with the server. Please check your '
                + 'connection and try again.',
              ],
            };
            this.formSubmitting = false;
          }
        // PAY IN FULL ON BOARDING
        } else {
          this.stripe.createToken(this.card).then((result) => {
            if (result.error) {
              const cardErrors = document.getElementById('card-errors');
              cardErrors.classList.add('alert');
              cardErrors.textContent = result.error.message;
              this.formSubmitting = false;
            } else {
              this.submitFormStep2('', result.token.id);
            }
          });
        }
      } else {
        this.submitFormStep2('', '');
      }
    },
    async submitFormStep2(customerId, token) {
      const postData = JSON.parse(JSON.stringify(this.formFields));
      delete postData.discount_code;
      postData.stripe_customer_id = customerId;
      postData.token = token;
      [postData.program, postData.payment_method] = postData.program.split('-');

      if (this.priceData[this.formFields.program].discounted.code_used !== undefined) {
        postData.discount_code = this.priceData[this.formFields.program].discounted.code_used;
      } else {
        postData.discount_code = '';
      }

      let response;

      try {
        response = await fetch(
          `${process.env.VUE_APP_API_URL}users/${this.userData.id}/enroll_new/`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Token ${this.userData.drf_token}`,
            },
            body: JSON.stringify(postData),
          },
        );
      } catch (e) {} // eslint-disable-line no-empty

      this.formSubmitting = false;

      if (response && [201, 400, 401].includes(response.status)) {
        const responseJson = await response.json();

        if (response.status === 201) {
          this.$store.commit('userData', responseJson);
          let versionType = 'english';
          const programTitle = this.programs.results.filter(item => item.id.toString() === postData.program);
          if (programTitle) {
            if (programTitle[0].title === 'Llegar al Ser en 8 Semanas') {
              versionType = 'spanish';
            }
          }
          this.$router.push({ name: 'welcome', params: { version: versionType } });
        } else if (response.status === 400) {
          this.formErrors = responseJson;
        } else {
          this.logUserOut();
        }
      } else {
        this.formErrors = {
          non_field_errors: [
            'Unable to communicate with the server. Please check your '
            + 'connection and try again.',
          ],
        };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  /deep/ .discount_code-form-group {
    > div {
      display: grid;
      grid-gap: 0.5rem;
      grid-template-columns: 1fr 6.6rem;
      align-items: start;
    }

    .btn {
      height: 38px;
    }
  }

  .total-cost {
    font-weight: bold;

    s {
      color: #ccc;
    }

    span {
      margin-left: 0.25rem;
      color: var(--success);
    }
  }
</style>
